/* eslint-disable no-underscore-dangle */
import { Component } from 'react';

import { BpkInlineLogo } from '@skyscanner-internal/bpk-logos';
import { isMobile } from 'react-device-detect';
import URLSearchParams from 'url-search-params';

import BpkBannerAlert, {
  ALERT_TYPES,
} from '@skyscanner/backpack-web/bpk-component-banner-alert';
import BpkImage from '@skyscanner/backpack-web/bpk-component-image';

import GetBrowser from './get-browser';

import STYLES from './App.scss';

const c = (className) => STYLES[className] || 'UNKNOWN';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      strings: {
        'en-GB': {
          ktxtCaptcha_robot_copy: { target: '' },
          ktxtCaptcha_robot_heading: { target: '' },
          ktxtCaptcha_robot_problems: { target: '' },
          ktxtHC_button: { target: '' },
          ktxtHC_ac_1: { target: '' },
          ktxtHC_ac_2: { target: '' },
        },
      },
      locale: this.getLocale(),
      stringsLoaded: false,
      failedToLoadStrings: false,
      verifiedCaptcha: false,
      failedToVerifyCaptcha: false,
      failedToDecodeBase64: false,
      errorMessage: '',
    };
    if (process.env.NODE_ENV !== 'production') {
      this.env = 'public-sandbox';
      this.staticPath = '';
    } else {
      this.env = 'public';
      this.staticPath = '//js.skyscnr.com/sttc/px/captcha-v2/';
    }
    this.serviceName = 'perimeterx';
    this.redirectURLBase64 = null;
    this.redirectURL = null;
    this.block_uuid = this.getUUID();
    this.vid = this.getQueryString('vid');
    this.browserInfo = new GetBrowser();
  }

  componentDidMount() {
    fetch('strings.json')
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          `Failed to fetch strings: HTTP request returned status=${response.status} statusText="${response.statusText}"`,
        );
      })
      .then((data) => {
        this.setState({
          strings: data,
          stringsLoaded: true,
        });
        this.initPX();
      })
      .catch(() => {
        this.setState({ failedToLoadStrings: true });
        this.initPX();
      });

    this.setRedirectURL();
  }

  /*
    componentDidUpdate - runs on state change, used to log events / mertics
  */
  componentDidUpdate(prevProps, prevState) {
    if (prevState.stringsLoaded !== this.state.stringsLoaded) {
      this.logApplog('INFO', 'Translation strings loaded', {
        event: 'TRANSLATIONS_LOADED',
      });
    }
    if (prevState.failedToLoadStrings !== this.state.failedToLoadStrings) {
      this.logApplog('EMERG', 'Failed to load translations', {
        event: 'TRANSLATIONS_FAILED_TO_LOAD',
      });
    }
    if (prevState.verifiedCaptcha !== this.state.verifiedCaptcha) {
      this.logApplog('INFO', 'Captcha verified', {
        event: 'CAPTCHA_VERIFIED',
      }).then(() => {
        if (this.redirectURL.startsWith('/')) {
          window.location.href = `${window.location.protocol}//${window.location.host}${this.redirectURL}`;
        } else {
          this.logApplog('WARN', 'Invalid redirect URL', {
            event: 'INVALID_REDIRECT_URL',
            errorMessage: `Invalid redirect URL: '${this.redirectURL}'`,
          }).then(() => {
            window.location.href = `${window.location.protocol}//${window.location.host}`;
          });
        }
      });
    }
    if (prevState.failedToVerifyCaptcha !== this.state.failedToVerifyCaptcha) {
      this.logApplog('EMERG', 'Captcha failed to verify', {
        event: 'CAPTCHA_FAILED_TO_VERIFY',
        errorMessage: this.state.errorMessage,
      });
    }
    if (prevState.failedToDecodeBase64 !== this.state.failedToDecodeBase64) {
      this.logApplog('EMERG', 'Failed to decode base64 url', {
        event: 'FAILED_TO_DECODE_BASE64_URL',
        errorMessage: this.state.errorMessage,
      });
    }
  }

  initPX = () => {
    window._pxAppId = this.getAppId();
    window._appId = window._pxAppId.substring(2); // first-party appid
    window._pxJsClientSrc = `/${window._appId}/init.js`;
    window._pxFirstPartyEnabled = true;
    window._pxVid = this.vid;
    window._pxUuid = this.block_uuid;
    window._pxHostUrl = `/${window._appId}/xhr`;
    window._pxBlockScript = `/${window._appId}/captcha.js?a=captcha&m=0&u=${window._pxUuid}&v=${window._pxVid}`;
    window._pxreCaptchaTheme = 'light';
    window._pxSelectedLocale = this.state.locale;
    window._pxOnCaptchaSuccess = this.verifiedCaptcha;

    window[`_${window._pxAppId}`] = {
      locale: this.state.locale,
      challenge: {
        translation: {
          [this.state.locale]: {
            btn: this.getTranslation('ktxtHC_button'),
            failed: this.getTranslation('ktxtHC_failed'),
            ac_1: this.getTranslation('ktxtHC_ac_1'),
            ac_2: this.getTranslation('ktxtHC_ac_2'),
            al_1: this.getTranslation('ktxtHC_al_1'),
            al_2: this.getTranslation('ktxtHC_al_2'),
          },
        },
      },
    };
    // mobile friendly translations
    if (isMobile) {
      window[`_${window._pxAppId}`].challenge.translation[
        this.state.locale
      ].btn = this.getTranslation('ktxtHC_button_mobile');
      window[`_${window._pxAppId}`].challenge.translation[
        this.state.locale
      ].ac_1 = this.getTranslation('ktxtHC_ac_1_mobile');
    }

    const p = document.getElementsByTagName('script')[0];
    const s = document.createElement('script');
    s.async = 1;
    s.src = window._pxBlockScript;
    p.parentNode.insertBefore(s, p);
  };

  getTranslation = (key) => {
    const desiredLocale = this.state.locale;
    if (
      Object.prototype.hasOwnProperty.call(this.state.strings, desiredLocale)
    ) {
      if (
        Object.prototype.hasOwnProperty.call(
          this.state.strings[desiredLocale],
          key,
        ) &&
        Object.prototype.hasOwnProperty.call(
          this.state.strings[desiredLocale][key],
          'target',
        )
      ) {
        return this.state.strings[desiredLocale][key].target;
      }
      if (this.state.stringsLoaded) {
        this.logApplog(
          'EMERG',
          `String "${key}" does not exist for locale "${desiredLocale}"`,
          { event: 'STRING_DOES_NOT_EXIST_FOR_LOCALE', desiredLocale, key },
        );
      }
    } else if (this.state.stringsLoaded) {
      this.logApplog(
        'EMERG',
        `Locale "${desiredLocale}" does not exist in strings`,
        { event: 'LOCALE_DOES_NOT_EXIST', desiredLocale },
      ).then(() => {
        this.setState({ locale: 'en-GB' });
      });
    }
    return '';
  };

  getLocale = () => {
    const regex = /ssculture=([^&]*&)*locale:::([^&]*)/g;
    const locale = regex.exec(document.cookie);
    return locale != null &&
      locale.length > 2 &&
      locale[2] !== '' &&
      locale[2].split('-').length === 2
      ? locale[2]
      : 'en-GB'; // Fallback
  };

  getQueryString = (name) => {
    const param = new URLSearchParams(
      document.location.search.substring(1),
    ).get(name);
    return param === null ? '' : encodeURIComponent(param);
  };

  setRedirectURL = () => {
    this.redirectURLBase64 = decodeURIComponent(this.getQueryString('url'));
    try {
      this.redirectURL = atob(this.redirectURLBase64);
    } catch (err) {
      this.redirectURL = '';
      this.setState({
        failedToDecodeBase64: true,
        errorMessage: `Failed to decode base64 url "${this.getQueryString(
          'url',
        )}"`,
      });
    }
  };

  getUUID = () => {
    const regex =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    const uuid = this.getQueryString('uuid');
    return regex.test(uuid) ? uuid : '';
  };

  logApplog = () => new Promise((resolve) => resolve());
  /*  Code removed for stop sending logs to slipstream due to project bonfire
    new Promise(resolve => {
      const defaultFields = {
        locale: this.state.locale,
        locales: Object.keys(this.state.strings).toString(),
        stringsLoaded: this.state.stringsLoaded.toString(),
        verifiedCaptcha: this.state.verifiedCaptcha.toString(),
        failedToVerifyCaptcha: this.state.failedToVerifyCaptcha.toString(),
        redirectURL: this.redirectURL,
        redirectURLBase64: this.redirectURLBase64,
        block_uuid: this.block_uuid,
        px_app: String(window._pxAppId),
        vid: this.vid,
        timeSincePageLoadMs: (Date.now() - window.__pageLoadedTime).toString(),
      };
      const severityLevels = {
        EMERG: 0,
        ALERT: 1,
        CRIT: 2,
        ERR: 3,
        WARN: 4,
        NOTICE: 5,
        INFO: 6,
        DEBUG: 7,
      };
      let severity = 0;
      if (
        Object.prototype.hasOwnProperty.call(severityLevels, messageSeverity)
      ) {
        severity = severityLevels[messageSeverity];
      }
      fetch(
        `https://slipstream.skyscanner.net/grp/v1/applog/${this.env}/${this.serviceName}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            message,
            fields: Object.assign(
              {},
              defaultFields,
              this.browserInfo.getBrowserInfo(),
              fields,
            ),
            severity,
          }),
        },
      )
        .then(response => {
          if (!response.ok) {
            console.log('Unable to log to slipstream');
          }
          resolve();
        })
        .catch(() => {
          console.log('Unable to log to slipstream');
          resolve();
        });
    });

End of code removed
*/

  verifiedCaptcha = (isValid) => {
    if (isValid) {
      this.setState({ verifiedCaptcha: true });
      return;
    }
    this.setState({ failedToVerifyCaptcha: true });
  };

  getAppId = () => {
    if (document.location.href.indexOf('sttc/px/captcha-v2-dev/') > -1) {
      return 'PXwTEJ208Q'; // Dev
    }
    return 'PXrf8vapwA'; // Prod
  };

  render() {
    let errorMessageComponent = null;

    if (this.state.failedToLoadStrings) {
      errorMessageComponent = (
        <BpkBannerAlert
          message="Unable to load translations, try refreshing the page?"
          type={ALERT_TYPES.ERROR}
          className={c('App__error')}
        />
      );
    }

    return (
      <div id="app_main" className={c('App')}>
        <section className={c('App__logo')}>
          <BpkInlineLogo alt="Skyscanner" className={c('App__logo')} />
        </section>
        <section className={c('App__headline')}>
          {this.getTranslation('ktxtCaptcha_robot_heading')}
        </section>
        <section className={c('App__image')}>
          <BpkImage
            altText=""
            src={`${this.staticPath}captcha-01.svg`}
            aspectRatio={284 / 150}
            className={c('App__img')}
          />
        </section>
        <section className={c('App__message')}>
          {this.getTranslation('ktxtCaptcha_robot_copy')}
          {errorMessageComponent}
        </section>
        <section className={c('App__captcha')}>
          <div id="px-captcha" />
        </section>
        <section className={c('App__resolve')}>
          {this.getTranslation('ktxtCaptcha_robot_problems')}
        </section>
        <section className={c('App__identifier')}>{this.block_uuid}</section>
      </div>
    );
  }
}

export default App;
